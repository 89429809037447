import * as React from 'react';
import {useEffect, useState} from 'react';
import Avatar from '@mui/material/Avatar';
import {styled} from "@mui/material/styles";
import {useSelector} from "react-redux";
import StarIcon from '@mui/icons-material/Star';

const CustomAvatar = styled(Avatar)`
  background: #FFFFFF
`
const randomColors = [
    "#3AA6B9",
    "#9575DE",
    "#917FB3",
    "#E8A0BF",
    "#3A4F7A",
    "#F65A83",
    "#372948",
    "#B93160",
    "#446A46",
    "#524A4E",
    "#694E4E",
    "#7897AB"
]

const ChipWrapper = styled('span')`
      display: flex;
    align-items: center;
    box-shadow: #fefefefe 0px 0px 0px 1px;
    border-radius: 10px;
    gap: 10px;
    font-weight: 600;
    color: rgb(40, 59, 112);
    font-size: 14px;
    overflow: hidden;
    padding: 10px 10px;
    background: #c8cfe72b;
`

const Vl = styled('div')`
    border-left: 2px solid rgb(40, 59, 112);
    height:22px;
`
// To get Icons Slug - https://github.com/simple-icons/simple-icons/blob/master/slugs.md

export default function SkillChipsV3({skillName, isFavourite, handleFavourite, shouldShowStar, experience, pointerEvents }) {
    const [isFavouriteLocal, setIsFavouriteLocal] = useState(false);
    const {skills = []} = useSelector((state) => {
        return {
            skills: state?.skills?.skills || [],
        }
    });
    const foundSkill = skills?.find(skill => skill.slug === skillName);
    const avatarSrc = `${process.env.REACT_APP_ICON_URL}${foundSkill?.icon || ""}`;

    const handleIsFavourite = () => {
        setIsFavouriteLocal(!isFavouriteLocal);
        if (handleFavourite) {
            handleFavourite(!isFavouriteLocal);
        }
    }

    useEffect(() => {
        setIsFavouriteLocal(isFavourite);
    }, [isFavourite])

    return (
        <ChipWrapper>

            {shouldShowStar ? <StarIcon style={{color: isFavouriteLocal ? "#ffe82f" : "#bfbfbf", cursor: "pointer", pointerEvents: pointerEvents ? pointerEvents : 'auto'}}
                                       onClick={() => handleIsFavourite()}/> : null}
            {foundSkill?.icon ? <span style={{ width: "22px", height: "22px"}}>
                <img alt={foundSkill?.name?.[0] || ""} src={avatarSrc} style={{ width: "22px", height: "22px"}} />
            </span> : null}
            <span style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace:"nowrap"}}>
                {foundSkill?.name}
            </span>
            {experience ?
                <>
                    <Vl/>
                    <span>
                        {experience > 10 ? `10+` : experience } {experience > 1 ? "Years" : "Year"}
                    </span>
                </> : null}
        </ChipWrapper>
    );
}
