
import React, { useEffect, useMemo } from "react";
import { IconButton } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FormHelperText from "@mui/material/FormHelperText";
import Autocomplete from "../../elements/Select/Autocomplete";
import SkillChips from "../../elements/SkillChip/SkillChipV3";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../store/actions";
import ColoredSlider from "../../elements/Slider";

export default function SelectSkills({
  handleSubmit,
  candidateSkills,
  removeExperience,
  selectedSkills,
  setSelectedSkills,
  skillInitialData,
  setSkillInitialData,
  errorState,
  setErrorState,
  setSkillNames
}) {

  const { skills = [] } = useSelector((state) => {
    return {
      skills: state?.skills?.skills || [],
    };
  });

  const dispatch = useDispatch();

  const skillsNames = useMemo(() => {
    const skillNames = {};
    skills.forEach(skill => {
      skillNames[skill.slug] = skill.name;
    });
    setSkillNames(skillNames);
    return skillNames;
  }, [skills])

  useEffect(() => {
    if (!skills?.length) {
      dispatch(actions.getSkills());
    }
  }, [skills]);

  useEffect(() => {
    if (selectedSkills?.length <= 0 && candidateSkills?.length > 0) {
      setSelectedSkills(candidateSkills)
      const allSelectedSkills = candidateSkills.map(({name}) => {
        const found = skills.find(({slug}) => slug === name);
        return found
      })
      setSkillInitialData(allSelectedSkills)
    }
  }, [candidateSkills?.length]);

  const handleOnchange = (changedSkills) => {
    const updatedSelectedSkills = changedSkills.map((value) => {
      const findSkill = selectedSkills.find(
        (skill) => skill.name === value?.slug
      );
      return {
        name: value?.slug,
        experience: 0,
        isPrimary: false,
        ...(findSkill || {}),
      };
    });

    const alreadySelectedSkills = [...selectedSkills, ...updatedSelectedSkills];

    const finalSkills = alreadySelectedSkills.filter((obj, index) => {
      return index === alreadySelectedSkills.findIndex(o => obj.name === o.name);
    });

    setSelectedSkills(finalSkills);
    setSkillInitialData(changedSkills);
  };

  const handleSubmitButton = () => {
    const localErrorState = {};
    selectedSkills.forEach(({ name, experience }) => {
      if (!removeExperience && !experience) {
        localErrorState[name] = true;
      }
    });
    if (Object.keys(localErrorState).length > 0) {
      setErrorState(localErrorState);
      return;
    }
    setErrorState(localErrorState);
    handleSubmit(selectedSkills);
  };

  return (
    <div>
      <Autocomplete
        renderOption={(props, option, state) => {
          if (!state?.inputValue && !!option?.alias) {
            return null;
          }
          return <li {...props}>{option.name}</li>;
        }}
        data={skills}
        displayLabel={"name"}
        selectedData={skillInitialData}
        equalKey={"slug"}
        onChange={handleOnchange}
        multiple={true}
        disableClearable={true}
        inputProps={{
          type: "text",
          variant: "outlined",
          placeholder: "Please select you skills",
          autoFocus: true,
          InputLabelProps: {
            shrink: false,
          },
          InputProps: {
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
            endAdornment: "",
          },
          size: "small",
        }}
      />
      <div
        style={{
          margin: "20px 0",
          overflow: "auto",
          height: "calc(100% - 80px)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: removeExperience ? "row" : "column",
            flexWrap: "wrap",
          }}
        >
          {selectedSkills?.map((skill, index) => {
            return (
              <div>
                <div
                  style={{
                    display: "flex",
                    margin: "10px 0",
                    alignItems: "center",
                    gap: "10px",
                  }}
                  key={`${skill.name}_${index}`}
                >
                  <div>
                    <SkillChips
                      skillName={skill?.name}
                      isFavourite={skill.isPrimary}
                      shouldShowStar={true}
                      handleFavourite={(value) => {
                        const updatedSkill = [
                          ...selectedSkills?.slice(0, index),
                          {
                            ...skill,
                            isPrimary: value,
                          },
                          ...selectedSkills?.slice(
                            index + 1,
                            selectedSkills.length
                          ),
                        ].sort(({ isPrimary }) => (isPrimary ? -1 : 0));
                        setSelectedSkills(updatedSkill);
                      }}
                    />
                  </div>
                  {!removeExperience ? (
                    <div style={{ marginLeft: "auto" }}>
                      <ColoredSlider
                        min={1}
                        max={10}
                        selectedValue={skill?.experience}
                        height={"40px"}
                        lowerTierBlockCount={6}
                        midTierBlockCount={2}
                        plus={true}
                        fontSize={"16px"}
                        onClick={(event, values) => {
                          setSelectedSkills([
                            ...selectedSkills?.slice(0, index),
                            {
                              ...skill,
                              experience: values,
                            },
                            ...selectedSkills?.slice(
                              index + 1,
                              selectedSkills.length
                            ),
                          ]);
                        }}
                      />
                    </div>
                  ) : null}
                  <div>
                    <IconButton color="error" size="small">
                      <CloseRoundedIcon
                        onClick={() => {
                          setSelectedSkills([
                            ...selectedSkills?.slice(0, index),
                            ...selectedSkills?.slice(
                              index + 1,
                              selectedSkills.length
                            ),
                          ]);
                          setSkillInitialData([
                            ...skillInitialData?.slice(0, index),
                            ...skillInitialData?.slice(
                              index + 1,
                              skillInitialData.length
                            ),
                          ]);
                        }}
                      />
                    </IconButton>
                  </div>
                </div>
                {errorState[skill.name] ? (
                  <FormHelperText
                    className="error"
                    style={{ float: "right", marginRight: "45px" }}
                  >
                    Experience can not be empty
                  </FormHelperText>
                ) : null}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
