import React, { memo, useMemo, useState } from "react"
import { format, isSameMinute, parseISO } from "date-fns"
import { CheckCircle2 } from "lucide-react"
import { cn } from "../../utils/globalFunctions"

const AvailableHours = memo(({ freeTimes, setSelectedTime, selectedTime }) => {
  return (
    <div className="flex flex-col items-center gap-2 mt-4 p-4">
      <span>
        Available times:{" "}
        <span className="font-semibold text-orange-950">
          {freeTimes.length}
        </span>
      </span>
      <div className="grid grid-cols-4 sm:grid-cols-5 md:grid-cols-6  text-md gap-4">
        {freeTimes.map((hour, hourIdx) => {
          
          const isSelectedSlot = selectedTime.find(time => isSameMinute(time, hour));
          return (
            <div key={hourIdx}>
              <button type="button"
                className={cn(
                  "text-green-700 border border-green-700 hover:bg-green-700 hover:text-white focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm p-2.5 text-center inline-flex items-center mr-2 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:focus:ring-green-800 dark:hover:bg-green-500 w-[60px] h-[40px]",
                  selectedTime.length &&
                  isSelectedSlot &&
                    "bg-green-700 text-white"
                )}
                onClick={() => {
                  if (!isSelectedSlot) {
                    setSelectedTime([...selectedTime, hour])
                  } else {
                    const index = selectedTime.map(d => d.toString()).indexOf(hour.toString());
                    if (index > -1) {
                      selectedTime.splice(index, 1);
                    }
                    setSelectedTime([...selectedTime])
                  }
                }}
                >
                {format(hour, "HH:mm")}
              </button>
              {/* <button
                type="button"
                className={cn(
                  "bg-green-200 rounded-lg px-2 text-gray-800 relative hover:border hover:border-green-400 w-[60px] h-[26px]",
                  selectedTime.length &&
                  isSelectedSlot &&
                    "bg-green-400 text-gray-800"
                )}
                onClick={() => {
                  if (!isSelectedSlot) {
                    setSelectedTime([...selectedTime, hour])
                  } else {
                    const index = selectedTime.indexOf(hour);
                    if (index > -1) {
                      selectedTime.splice(index, 1);
                    }
                    setSelectedTime([...selectedTime])
                  }
                }}
              >
                <CheckCircle2
                  className={cn(
                    "w-[16px] h-[16px] absolute hidden top-0 right-0 transform translate-x-1 -translate-y-1.5 text-green-700",
                    selectedTime.length && isSelectedSlot && "block"
                  )}
                />
                {format(hour, "HH:mm")}
              </button> */}
            </div>
          )
        })}
      </div>
      {/* {selectedTime.length && (
        <div className="w-full py-6">
          <span>Final selected reservation time is: </span><br />
          {
            selectedTime.map(time => (
              <>
                <span className="font-semibold text-rose-950 pl-1" key={time}>
                  {format(time, "dd MMMM yyyy HH:mm")}
                </span>
                <br />
              </>
            ))
          }
        </div>
      )} */}
    </div>
  )
})

export default AvailableHours