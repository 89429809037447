import React, { useMemo, useState, useEffect } from "react"
import { useParams } from 'react-router-dom';
import { Box, Grid, FormGroup, FormControlLabel, Checkbox, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { withSwal } from 'react-sweetalert2';
import { cn, dayNames } from "../../utils/globalFunctions"
import {
  add,
  addDays,
  addHours,
  eachDayOfInterval,
  eachMinuteOfInterval,
  endOfDay,
  endOfMonth,
  endOfWeek,
  format,
  getDay,
  isAfter,
  isBefore,
  isEqual,
  isSameMonth,
  isThisMonth,
  isToday,
  parse,
  parseISO,
  set,
  startOfDay,
  startOfToday,
  startOfWeek,
  startOfYesterday,
} from "date-fns"
import { CheckCircle2, ChevronLeft, ChevronRight } from "lucide-react"
import AvailableHours from "./hours"
import TimesBar from "./timesBar"
import { START_HOUR, END_HOUR, STEPS } from './timeConfig'
import './index.css';

import { fetchCandidateAvailability, saveCandidateAvailability } from "../../services/candidate.service";

const SaveButton = styled(Button)(() => ({
  color: '#FFFFFF',
  backgroundColor: '#283665 !important' ,
  '&:hover': {
    backgroundColor: '#283665',
  },
  width: "125px",
}));

const Calendar = ({...props}) => {
  
  // display div of availables times
  const [calendarTouched, setCalendarTouched] = useState(false)
  const [selectedTime, setSelectedTime] = useState([])

  // handle dates
  let today = startOfToday()
  let [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"))
  let [selectedDay, setSelectedDay] = useState(today)
  let [totalSelectedDays, setTotalSelectedDays] = useState([today])
  let [isAlwaysAvailable, setIsAlwaysAvailable] = useState(false);
  let firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date())
  let days = eachDayOfInterval({
    start: startOfWeek(firstDayCurrentMonth, { weekStartsOn: 1 }),
    end: endOfWeek(endOfMonth(firstDayCurrentMonth), { weekStartsOn: 1 }),
  })

  // reservations array 
  const reservations = [];


  // send available times for the selected day to the hours component
  let [freeTimes, setFreeTimes] = useState([])

  // all days avaiilable times in this month until you change it 
  const [availableTimesInThisMonth, setAvailableTimesInThisMonth] = useState([])
  const [availableTimesInThisMonthForEachDay, setAvailableTimesInThisMonthForEachDay] = useState([])

  const { candidateUuid } = useParams();

  const getCandidateAvailability = async () => {
    const response = await fetchCandidateAvailability(candidateUuid);
    if (response?.statusCode === 200) {
      const existingSlots = response?.data?.availability?.availableSlots?.map(slot => parseISO(slot));
      setSelectedTime(existingSlots);
      const selectedDays = response?.data?.availability?.availableSlots?.map(slot => startOfDay(parseISO(slot)));
      setTotalSelectedDays(selectedDays);
      setIsAlwaysAvailable(response?.data?.availability?.alwaysAvailable);
    } else {
      props.swal.fire({
        title: 'Error',
        text: `Error while fetching Candidate availability, Please try again later`,
        icon: 'error',
      });
    }
  }

  useEffect(() => {
    getCandidateAvailability();
  }, []);

  // next and prev month functions
  function prevMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 })
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"))
  }
  function nextMonth() {
    let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 })
    setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"))
  }

  // get available times for the selected day
  useMemo(() => {
    const StartOfToday = startOfDay(selectedDay)
    const endOfToday = endOfDay(selectedDay)
    // change your working hours here
    const startHour = set(StartOfToday, { hours: START_HOUR })
    const endHour = set(endOfToday, { hours: END_HOUR })
    let hoursInDay = eachMinuteOfInterval(
      {
        start: startHour,
        end: endHour,
      },
      { step: STEPS }
    )

    // filter the available hours
    let freeTimes = hoursInDay.filter(
      (hour) => !reservations.includes(parseISO(hour.toISOString()).toString())
    )
    setFreeTimes(freeTimes)
    
  }, [selectedDay])

  // calculate the number of available times for each day in this month
  useMemo(() => {
    let thisMonthTimesLength = []
    let thisMonthTimesEachDay = []
    days.map((day, dayIdx) => {
      // get times

      const StartOfToday = startOfDay(day)
      const endOfToday = endOfDay(day)
      // change your working hours here
      const startHour = set(StartOfToday, { hours: START_HOUR })
      const endHour = set(endOfToday, { hours: END_HOUR })
      let hoursInDay = eachMinuteOfInterval(
        {
          start: startHour,
          end: endHour,
        },
        { step: STEPS }
      )
      // filter the available hours
      let freeTimes = hoursInDay.filter(
        (hour) =>
          !reservations.includes(parseISO(hour.toISOString()).toString())
      )
      thisMonthTimesLength.push(freeTimes.length)
      thisMonthTimesEachDay.push(freeTimes)
    })

    setAvailableTimesInThisMonth(thisMonthTimesLength)
    setAvailableTimesInThisMonthForEachDay(thisMonthTimesEachDay)

  }, [currentMonth])

  const handleAlwaysAvailable = (event) => {
    setIsAlwaysAvailable(event.target.checked);
    setFreeTimes([]);
    setSelectedDay(today);
    setTotalSelectedDays([today]);
    setSelectedTime([]);
  }

  const handleSaveAvailibility = async () => {
    const requestBody = {
      availability: {
        alwaysAvailable: isAlwaysAvailable,
        availableSlots: selectedTime,
      }
    };
    await saveCandidateAvailability(candidateUuid, requestBody)
      .then(response => {
        if (response?.statusCode === 200) {
          props.swal.fire({
            title: 'Success',
            text: `Your availability updated successfully, HR will contact you soon.`,
            icon: 'success',
          });
          getCandidateAvailability();
        } else {
          props.swal.fire({
            title: 'Oops',
            text: `Error while saving availability, please try again`,
            icon: 'error',
          });
        }
      }).catch(error => {
        props.swal.fire({
          title: 'Oops',
          text: `Error while saving availability, please try again`,
          icon: 'error',
        });
      });
  }

  return (
    <div className="flex flex-col min-h-screen justify-center items-center gap-2">
      <div className={cn("flex flex-col gap-2 justify-center items-center")}>
        <span className="font-serif text-xl sm:text-2xl font-semibold text-teal-900 px-4 sm:px-2">
          Candidate Availability
        </span>
      </div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            {/* calendar implementation */}
            <div className="flex flex-col gap-2 h-[390px] w-[380px] mt-12">
              {/* calendar header */}
              <div className="grid grid-cols-3">
                <button
                  type="button"
                  onClick={prevMonth}
                  disabled={isThisMonth(new Date(currentMonth))}
                >
                  <ChevronLeft
                    size={20}
                    aria-hidden="true"
                    className={cn(
                      isThisMonth(new Date(currentMonth)) && "text-gray-300"
                    )}
                  />
                </button>
                <h2 className="font-semibold text-orange-950 justify-center flex">
                  {format(firstDayCurrentMonth, " MMMM yyyy")}
                </h2>
                <button
                  type="button"
                  className="flex justify-end"
                  onClick={nextMonth}
                >
                  <ChevronRight size={20} aria-hidden="true" />
                </button>
              </div>

              {/* calendar body */}
              <div>
                <div className="grid grid-cols-7 mt-4">
                  {dayNames.map((day, i) =>
                  {
                    return(
                    <div
                      key={i}
                      className={cn(
                        "flex justify-center items-center text-sm text-blue-500 w-full py-2",
                        {
                          "text-orange-400 bg-orange-50 rounded-t-lg":
                            day === "Sun" || day === "Sat",
                        }
                      )}
                    >
                      {day}
                    </div>
                  )})}
                </div>

                <div className="grid grid-cols-7 text-sm">
                  {days.map((day, dayIdx) => {
                    const currentSelectedDay = totalSelectedDays.find(currentDay => currentDay.toString() === day.toString());
                    return (
                      <div
                        key={day.toString()}
                        className={cn(
                          dayIdx === 0 && colStartClasses[getDay(day) - 1],
                          "h-14 justify-center flex items-center",
                          (getDay(day) === 0 || getDay(day) === 6) &&
                            "bg-orange-50 rounded-lg"
                        )}
                      >
                        <button
                          onClick={() => {
                            setCalendarTouched(true)
                            setSelectedDay(day)
                            const isSlotSelected = selectedTime.find(time => format(time, "dd MMMM yyyy").toString() === format(day, "dd MMMM yyyy").toString());
                            const newSelectedDays = [...totalSelectedDays].filter(d => {
                              const isSlotAvailable = selectedTime.find(time => format(time, "dd MMMM yyyy").toString() === format(d, "dd MMMM yyyy").toString())
                              if (isSlotAvailable) {
                                return d
                              }
                            })
                            if (!currentSelectedDay) {
                              setTotalSelectedDays([...newSelectedDays, day]);
                            } else if (isSlotSelected) {
                              // const index = newSelectedDays.map(d => d.toString()).indexOf(day.toString());
                              // if (index > -1) {
                              //   newSelectedDays.splice(index, 1);
                              // }
                              setTotalSelectedDays([...newSelectedDays])
                            }
                          }}
                          className={cn(
                            "w-12 h-12 flex flex-col p-2 justify-center items-center rounded-xl gap-0 group bg-gray-50 relative group",
                            isEqual(day, currentSelectedDay) &&
                              "bg-orange-100 text-slate-900 text-lg",
                            isEqual(today, day) && "text-blue-900 bg-blue-50",
                            isBefore(day, today) &&
                              "text-red-800 bg-red-50 cursor-not-allowed",
                            isEqual(today, day) && "text-blue-900 bg-blue-50",
                            isBefore(day, today) && "cursor-not-allowed",
                            isEqual(day, currentSelectedDay) &&
                              isToday(day) &&
                              "bg-blue-200",
                            !isEqual(day, currentSelectedDay) &&
                              !isToday(day) &&
                              !isSameMonth(day, firstDayCurrentMonth) &&
                              "text-gray-400",
                            !isEqual(day, currentSelectedDay) &&
                              !isToday(day) &&
                              isSameMonth(day, firstDayCurrentMonth) &&
                              "text-gray-900"
                          )}
                          disabled={isBefore(day, today)}
                        >
                          {/* {isAfter(day, startOfYesterday()) && (
                            <span className="hidden group-hover:flex absolute top-0 -translate-x-.5 -translate-y-4 z-10 text-[11px] bg-slate-900 text-slate-100 px-1 rounded-md gap-1">
                              <span>{availableTimesInThisMonth[dayIdx]}</span>
                              <span>Available</span>
                            </span>
                          )} */}

                          <time
                            dateTime={format(day, "yyyy-MM-dd")}
                            className={cn(
                              "group-hover:text-lg",
                              (isEqual(day, currentSelectedDay) || isToday(day)) &&
                                "font-semibold"
                            )}
                          >
                            {format(day, "d")}
                          </time>

                          <CheckCircle2
                            className={cn(
                              "hidden",
                              isEqual(day, currentSelectedDay) &&
                                "absolute block top-0 right-0 h-[18px] w-[18px] translate-x-1 -translate-y-1 text-orange-900",
                              isEqual(day, today) && "text-blue-900"
                            )}
                          />

                          {isAfter(day, startOfYesterday()) && (
                            <TimesBar
                              times={availableTimesInThisMonthForEachDay[dayIdx]}
                            />
                          )}
                        </button>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
          <div className={cn(`hidden`, calendarTouched && "block")}>
            <span className="flex items-center w-full justify-center gap-1">
              <span>
                Select your reservation time for
                <span className="text-orange-950 font-semibold pl-1">
                  {format(selectedDay, "dd MMMM yyyy").toString()}
                </span>
              </span>
            </span>
            
            <AvailableHours
              key={selectedTime?.length}
              freeTimes={freeTimes}
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
            />
          </div>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: '2rem' }}>
          <Grid item xs={12} md={12}>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox 
                      name="leadershipExpNeeded"
                      checked={isAlwaysAvailable}
                      onChange={handleAlwaysAvailable}
                    />
                  }
                  label="I am always available"
                />
              </FormGroup>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <SaveButton onClick={handleSaveAvailibility}>Submit</SaveButton>
          </Grid>
        </Grid>
      </Box>
    </div>
  )
}

let colStartClasses = [
  "",
  "col-start-2",
  "col-start-3",
  "col-start-4",
  "col-start-5",
  "col-start-6",
  "col-start-7",
]

export default withSwal(Calendar);