import React from "react";
import {Avatar} from "@mui/material";
import {Summary} from "../card/common";
import CandidateSkillChart from "../chart/CandidateSkillChart";
import {styled} from "@mui/material/styles";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PublicIcon from '@mui/icons-material/Public';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {SubTitle, Title} from "../../elements/headers";

const ProfileAbout = styled("div")`
    display: grid;
    grid-template-columns: 240px auto;
    gap: 10px;
`

const ProfileSummary = styled("div")`
    display: grid;
    grid-template-columns: auto 300px;
    gap: 20px;
`

const LocationWrapper = styled("div")`
    display: grid; 
    grid-template-columns: repeat(2,1fr);
    gap: 5px;
    position: relative;
    ::after {
        content: " ";
        display: block;
        position: absolute;
        width: 2px;
        left: -10px;
        /* top: 5px; */
        height: 100%;
        opacity: 0.8;
        margin: auto;
        border-left: 1px dotted rgb(0 0 0 / 80%);
    }
`

const ProfileSkill = styled("div")`
    display: grid; 
    grid-template-columns: repeat(2,1fr);
    margin-top: 25px;
    gap: 15px;
`

const Label = styled("span")`
    display: flex;
    align-items: center;
    gap:2px;
    font-size: 0.9rem;
    font-weight: 600;
    margin: 10px 0;
    color: rgb(102, 102, 102);
    height: 30px;
`

const LabelValue = styled("span")`
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 500;
    margin: 10px 0;
    height: 30px;
`


function CandidateSummary({
                              candidate: {
                                  name = "",
                                  photo = "",
                                  title = "",
                                  summary = "",
                                  address: {city = "", country = ""} = {},
                                  timezone = "",
                                  availability = "",
                                  compensation: {monthlyRate = ""} = "",
                                  primarySkills = [],
                                  secondarySkills = []
                              } = {},
                              candidate
                          }) {
    return (

        <React.Fragment>
            <ProfileAbout>
                <Avatar src={photo} variant="rounded" style={{width: "100%", height: "100%"}}>
                    {name[0]}
                </Avatar>
                <div>
                    <Title>
                        {name}
                    </Title>
                    <SubTitle style={{margin: 0}}>
                        {title}
                    </SubTitle>
                    <ProfileSummary>
                        <Summary
                            title={summary}>
                            {summary}
                        </Summary>
                        <LocationWrapper>
                            <div>
                                <Label><LocationOnIcon/>Location</Label>
                                <Label><PublicIcon/>Timezone</Label>
                                <Label><AccessTimeIcon/>Availability</Label>
                                <Label><AttachMoneyIcon/>Compensation</Label>
                            </div>
                            <div>
                                <LabelValue>{city}, {country?.name || ""}</LabelValue>
                                <LabelValue>{timezone}</LabelValue>
                                <LabelValue>{availability}</LabelValue>
                                <LabelValue>USD {monthlyRate}/monthly</LabelValue>
                            </div>
                        </LocationWrapper>
                    </ProfileSummary>
                </div>
            </ProfileAbout>
            <ProfileSkill>
                <div>
                    <CandidateSkillChart data={primarySkills} title={"Primary skills"}/>
                </div>
                <div>
                    <CandidateSkillChart data={secondarySkills} title={"Secondary skills"}/>
                </div>
            </ProfileSkill>
        </React.Fragment>
    )
}

export default CandidateSummary;
