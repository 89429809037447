import {styled} from "@mui/material/styles";
import {useEffect, useState} from "react";

const ValueChip = styled("span")`
    background: ${({backgroundColor, noColor}) => noColor ? "whitesmoke" : backgroundColor};
    color: ${({noColor}) => noColor ? "black" : "white"};
    width: ${({height})=>height ? `${height}`: "100%"};
    height: ${({height})=>height ? `${height}`: "100%"};
    display: flex;
    font-size: ${({fontSize}) => fontSize || "18px"};
    font-weight:600;
    text-align: center;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    border: ${({isSelected}) => isSelected ? "1px solid black" : "none"};
    opacity: ${({isSelected})=> isSelected ? 1 : 0.7};
`

const RatingWrapper = styled("div")`
    display: flex;
    gap: 1px;
    width: 100%;
    height: 100%;
`

const ColoredSlider = ({
                           min = 0,
                           max,
                           plus,
                           lowerTierBlockCount = 0,
                           midTierBlockCount = 0,
                           lowerTierColor = "#ff767b",
                           midTierColor = "#f9bd09",
                           highTierColor = "#79cd24",
                           fontSize,
                           selectedValue,
                           noColor,
                            height,
                            onClick
                       }) => {
    const [selectedChip, setSelectedChip] = useState();
    useEffect(() => {
        if (selectedChip === undefined && selectedValue !== undefined) {
            setSelectedChip(selectedValue)
        }
    }, [selectedValue])

    const allValueChip = [];

    const onClickChip = (e, value) => {
        setSelectedChip(value)
        if (onClick)
            onClick(e, value)
    }
    for (let i = min; i<=max; i++) {
        const color = (lowerTierBlockCount && i < lowerTierBlockCount) ? lowerTierColor : i < (lowerTierBlockCount + midTierBlockCount) ? midTierColor : highTierColor
        const currentValue = plus && i === max ? 999 : i;
        allValueChip.push(
            <ValueChip
                noColor={noColor}
                fontSize={fontSize}
                backgroundColor={color}
                isSelected={currentValue === selectedChip}
                onClick={(e) => onClickChip(e, plus && i === max ? 999 : i)}
                height={height}
            >
                {plus && i === max ? `${i}+` : i}
            </ValueChip>
        )
    }

    return (
        <RatingWrapper>
            {allValueChip}
        </RatingWrapper>
    )
}

export default ColoredSlider
