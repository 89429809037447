// import { lazy } from 'react';

// import Layout from '../components/layout';
// import Loadable from '../elements/Loadable';
import Proposal from "../pages/proposal";

// const UIPage = Loadable(lazy(() => import('../pages/UI')));
import ScreeningPage from "../pages/screening";
import CalendarAvailabilityPage from "../pages/calendar";
import CandidateProfile from "../pages/Candidate";
import CandidatePreScreening from "../pages/candidatePreScreening";
import PageNotFound from '../pages/ErrorPages/PageNotFound';

const MainRoutes = {
    path: '/',
    children: [
        {
            path: '404',
            element: <PageNotFound />,
        },
        {
            path: '/proposal/:proposalUuid',
            element: <Proposal/>
        },
        {
            path: '/screening/:screeningUuid',
            element: <ScreeningPage/>
        },
        {
            path: '/calendar/:candidateUuid',
            element: <CalendarAvailabilityPage/>
        },
        {
            path: '/candidate/:candidateId',
            element: <CandidateProfile/>
        },
        {
            path: '/candidate-registration',
            element: <CandidatePreScreening />
        },
    ]
};

export default MainRoutes;
