import CommaSvg from "../../../assets/comma.svg";
import EducationSvg from "../../../assets/education.svg";
import React from "react";

const RightSideProfile = ({candidateData, modifiedSkill}) => {
    return (
        <div className="right-profile">
            <div className="profile-summary section-padding pb-0 border-0 section-margin-right">
                <h2 className="profile-titles">PROFILE SUMMARY</h2>
                <p className="profile-desc">{candidateData?.about?.summary ?? ""}</p>
            </div>
            <div className="tech-proficiencies section-padding border-0 section-margin-right">
                <h2 className="profile-titles">TECHNICAL PROFICIENCIES</h2>
                <div className="proficiencies-boxes">
                    {candidateData?.skills?.primarySkills?.map(({name, experience}) => {
                        const foundSkill = modifiedSkill?.[name];
                        const avtarSrc = `https://cdn.simpleicons.org/${foundSkill?.icon || ""}`;

                        return (
                            <div className="proficiencies-item">
                                <figure>
                                    <img
                                        alt={"img error"}
                                        data-src="https://www.uplers.com/wp-content/themes/uplers/assets/images/profiles/html-tech.svg"
                                        className=" lazyloaded"
                                        src={avtarSrc}
                                    />
                                </figure>
                                <p className="mb-0 tech">{foundSkill?.name}</p>
                                <p className="mb-0 years">{experience} Years</p>
                                <div className="prof-line"/>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="push-boundaries">
                <h2>Let's push the boundaries of growth and success – Together.</h2>
                <a href="https://www.uplers.com/hire-remote-talent" className="cta-button">
                    Hire Now
                </a>
            </div>
            <div className="pro-exp list-comm section-padding border-0 section-margin-right">
                <h2 className="profile-titles">PROFESSIONAL EXPERIENCE</h2>
                <div className="exp-boxes">
                    {candidateData?.workExperience?.slice(0, 3)?.map(({
                                                                          companyName,
                                                                          fromYear,
                                                                          toYear,
                                                                          summary,
                                                                          title,
                                                                      }) => {
                        const summaryList = summary?.split("->").filter((value) => !!value)
                        return (
                            <div className="exp-box">
                                <p className="exp-title">{title}</p>
                                <p className="exp-sub">{companyName} | {fromYear} - {toYear}</p>
                                <ul className="exp-desc">
                                    {summaryList.map((value, index) => {
                                        return <li
                                            className={(index === (summaryList?.length - 1) ? "mb-0" : "")}>{value}</li>
                                    })}
                                </ul>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="cl-testimonial">
                <div className="icon">
                    <figure>
                        <img
                            alt={"img error"}
                            data-src="https://www.uplers.com/wp-content/themes/uplers/assets/images/profiles/comma.svg"
                            className=" ls-is-cached lazyloaded"
                            src={CommaSvg}
                        />
                    </figure>
                </div>
                <div className="testimonial-desc">
                    <p>
                        Rahul worked with me in building/coding advanced websites and working with
                        big
                        APIs. He is a
                        person who has plenty of motivation and discipline. He is consciously
                        proactive
                        at getting the
                        full involvement of all other team members to derive the best possible
                        results.
                    </p>
                </div>
                <p>- Client Testimonial</p>
            </div>
            <div className="major-pro list-comm pb-0 section-padding border-0 section-margin-right">
                <h2 className="profile-titles">MAJOR PROJECTS</h2>
                {candidateData?.pastProjects?.slice(0, 3)?.map(({
                                                                    name,
                                                                    summary,
                                                                }) => {
                    const summaryList = summary?.split("->").filter((value) => !!value)
                    return (
                        <div className="major-boxes">
                            <div className="major-box">
                                <p className="exp-title">{name}</p>
                                {/*<p className="exp-sub">Nov 2019 - Jun 2020 | Worked as a Full-stack Web
                                                    Developer</p>*/}
                                <ul className="exp-desc">
                                    {summaryList.map((value, index) => {
                                        return <li
                                            className={(index === (summaryList?.length - 1) ? "mb-0" : "")}>{value}</li>
                                    })}
                                </ul>
                            </div>
                        </div>
                    )
                })}
            </div>
            <div className="tal-education section-padding pb-0 border-0 section-margin-right">
                <h2 className="profile-titles">EDUCATION</h2>
                <div className="education-container">
                    {candidateData?.educationSection?.slice(0, 3)?.map(({
                                                                            universityName,
                                                                            degreeName,
                                                                            fromYear,
                                                                            toYear
                                                                        }) => {
                        return (
                            <div className="education-box">
                                <figure><img
                                    alt={"img error"}
                                    className=" ls-is-cached lazyloaded"
                                    src={EducationSvg}/>
                                </figure>
                                <div className="education-detials">
                                    <p className="ed-year">{fromYear} - {toYear}</p>
                                    <p className="ed-class">{degreeName}</p>
                                    <p className="ed-insitute mb-0">{universityName}</p>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="tools-app section-padding section-margin-right border-0">
                <h2 className="profile-titles">Applications &amp; Tools Known</h2>
                <div className="tools-grid">
                    {[...(candidateData?.skills?.primarySkills ?? []), ...(candidateData?.skills?.secondarySkills ?? [])]?.map(({
                                                                                                                                    name
                                                                                                                                }) => {
                        const foundSkill = modifiedSkill?.[name];
                        const avtarSrc = `https://cdn.simpleicons.org/${foundSkill?.icon || ""}`;

                        return (
                            <div className="tools-box">
                                <figure>
                                    <img
                                        alt={"img error"}
                                        className=" ls-is-cached lazyloaded"
                                        style={{width: 49, height: 49}}
                                        src={avtarSrc}
                                    />
                                </figure>
                                <p className="mb-0">{foundSkill?.name}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}

export default RightSideProfile;
