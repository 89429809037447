import {Summary, Title} from "../card/common";
import Card from "../../elements/card";
import React from "react";
import {styled} from "@mui/material/styles";
import {CalendarMonth, Work} from "@mui/icons-material";
import {SubTitle, SubTitleH3, SubTitleH4} from "../../elements/headers";

const Timeline = styled("div")`
    position: relative;
    padding-left: 150px;
     :before {
        content: "";
        position: absolute;
        width: 6px;
        background-color: #283556;
        top: 0;
        bottom: 0;
        height:100%
     }
`

const Container = styled("div")`
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    
    :before {
        content: " ";
        height: 0;
        position: absolute;
        top: 22px;
        width: 0;
        z-index: 1;
        left: 30px;
        border: medium solid black;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }
`

const TimelineIcon = styled("div")`
        position: absolute;
        width: 30px;
        height: 30px;
        background-color: #283556;
        top: 20px;
        border-radius: 50%;
        z-index: 1;
        left: -12px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        justify-content: center;
        align-items: center;
`

const TimelineInfo = styled(SubTitleH3)`
        position: absolute;
        border-radius: 50%;
        margin:0;
        top: 24px;
        gap: 5px;
        z-index: 1;
        left: -140px;
        display: flex;
        align-items: center;
        justify-content: center;
        display: flex;
        justify-content: center;
        align-items: center;
`

function CandidateWorkExperience({candidate: {workExperience = []} = {}}) {
    return (
        <React.Fragment>
            <div>
                <Title>
                    Work Experience
                </Title>
                <Timeline className="timeline" style={{marginTop: 15}}>
                    {workExperience.map(({
                                             companyName,
                                             fromYear,
                                             toYear,
                                             responsibility,
                                             summary,
                                             title,
                                             location: {city, state, country}
                                         }) => {
                        return <Container>
                            <TimelineIcon>
                                <Work style={{color: "white", width: 16, height: 16}}/>
                            </TimelineIcon>
                            <TimelineInfo>
                                <CalendarMonth/> <span>{fromYear} - {toYear}</span>
                            </TimelineInfo>
                            <Card mainCardStyle={{boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px"}}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        borderBottom: "1px solid #BFC3C7"
                                    }}
                                >
                                    <SubTitle style={{margin: "0.7em 0"}}>{title}</SubTitle>
                                </div>
                                <SubTitleH4 style={{margin: 0}}>
                                    {companyName}
                                </SubTitleH4>
                                {summary ?
                                    <>
                                        <SubTitleH3>Summary</SubTitleH3>
                                        <Summary>
                                            <ul>
                                                {summary?.split("->").filter((value) => !!value).map((value) => {
                                                    return <li>{value}</li>
                                                })}
                                            </ul>
                                        </Summary>
                                    </> : ""}

                                {responsibility ? <>
                                    <SubTitleH3>Responsibilities</SubTitleH3>
                                    <Summary>
                                        <ul>
                                            {responsibility?.split("->").filter((value) => !!value).length > 1 ? responsibility?.split("->").filter((value) => !!value).map((value) => {
                                                return <li>{value}</li>
                                            }) : responsibility?.split("-").filter((value) => !!value).map((value) => {
                                                return <li>{value}</li>
                                            })}
                                        </ul>
                                    </Summary>
                                </> : ""}
                            </Card>
                        </Container>
                    })}

                </Timeline>
            </div>
        </React.Fragment>
    )
}

export default CandidateWorkExperience
