import React from "react";

import {IconButton} from '@mui/material';

import {styled} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const InputContainer = styled(TextField)`
  & label.Mui-focused {
    color: #283556;
  }
  & .MuiOutlinedInput-root {
    &.Mui-focused fieldset {
      border-color: #283556;
    }
    &:hover fieldset {
      border-color: #283556;
    }
    & .Mui-disabled {
      -webkit-text-fill-color: rgba(0, 0, 0, 1);
    }
  }
  & .MuiInputLabel-root {
    margin-top: 0px;
  } 
`;

export const InputLabelContainer = styled(InputLabel)`
  margin-bottom: 0.5rem;
`;

const CustomInput = React.forwardRef((props, ref) => {
    return (
        <>
            {props?.title ? <InputLabelContainer>{props?.title}</InputLabelContainer> : null}
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between", ...(props?.inputContainerStyle || {})
            }}>
                <div className={"w-100"}>
                    <InputContainer
                        inputRef={(me) => {
                            if (ref) {
                                if (!ref.current) ref.current = {}
                                ref.current["element"] = me
                                if (me) ref.current["value"] = me.value
                            }
                        }}
                        onChange={(e) => {
                            if (props.onChange) props.onChange(e)
                            if (ref) {
                                ref.current["value"] = e.target.value
                            }
                        }}
                        {...props}
                        className={`${props?.isClose ? "w-95" : props?.isHalfClose ? "w-90" : "w-100"} ${!props?.title && !props?.isClose && !props?.noMargin ? 'margin-top' : ''}`}
                    />
                    {(props?.error && !props?.hideErrorMessage) || props?.helperText ? (
                        <FormHelperText className="error">
                            {props?.error || props?.helperText}
                        </FormHelperText>
                    ) : null}
                </div>
                {
                    props?.isClose ?
                        <IconButton color="error" size="large">
                            <CloseRoundedIcon onClick={props?.handleCancel}/>
                        </IconButton>
                        : null
                }
            </div>
        </>
    )
})

export default CustomInput;
