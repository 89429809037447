import {configureStore} from "@reduxjs/toolkit";
import thunk from 'redux-thunk';
import reducer from './reducers';

const store = configureStore({
    reducer: reducer,
    preloadedState: {},
    devTools: process.env.NODE_ENV === "development",
    middleware: [thunk],
});

const persister = 'Free';

export { store, persister };
