import requestWithToken from "../apiConfigs/requestWithToken";

export const fetchCandidateScreeningProfile = (screeningUuid) => {
    return requestWithToken({
      url: `/screening/candidate/${screeningUuid}`,
      method: 'GET'
    });
  }

export const saveCandidateScreeningProfile = (screeningUuid, data) => {
  return requestWithToken({
    url: `/screening/candidate/${screeningUuid}`,
    method: 'PATCH',
    data,
  });
}

export const getCandidateRegistrationFields = () => {
  return requestWithToken({
    url: `/screening/candidate-registration-fields`,
    method: 'GET',
  });
}

export const fetchJobTemplates = () => {
  return requestWithToken({
    url: `/public/job-templates`,
    method: 'GET',
  });
}

export const registerCandidateScreeningProfile = (data) => {
  return requestWithToken({
    url: `/screening/candidate-registration`,
    method: 'POST',
    data,
  });
}