import { clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export const isJsonString = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function cn(...inputs) {
  return twMerge(clsx(inputs))
}

export const dayNames = [
  'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun',
]

export const isValidName = (name) => {
  return /^[a-zA-Z]/.test(name); // Check first character is alphabet not number
}