import requestWithToken from "../apiConfigs/requestWithToken";

export const fetchCandidateAvailability = (candidateUuid) => {
  return requestWithToken({
    url: `/public/calendar/${candidateUuid}`,
    method: 'GET'
  });
}

export const saveCandidateAvailability = (candidateUuid, data) => {
  return requestWithToken({
    url: `/public/calendar/${candidateUuid}`,
    method: 'PATCH',
    data,
  });
}

export const fetchCandidateDetails = (candidateId) => {
    return requestWithToken({
        url: `/candidates/${candidateId}`,
        method: 'GET'
    });
}

export const fetchSkills = (candidateId) => {
    return requestWithToken({
        url: `/skills`,
        method: 'GET'
    });
}
