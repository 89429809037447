import React from "react";
import { styled } from '@mui/material/styles';

import PhoneInput from 'react-phone-input-2'
import InputLabel from '@mui/material/InputLabel';

import './styles.scss'

const InputLabelContainer = styled(InputLabel)`
  margin-bottom: 0.5rem;
`;

const Phone = (props) => {
  return (
    <>
      <InputLabelContainer>{props?.title}</InputLabelContainer>
      <PhoneInput {...props} specialLabel={''} />
    </>
  )
}

export default Phone;