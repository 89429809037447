import React from "react"
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import {Card, CardContent} from "@mui/material";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import ReadOnly from "../../elements/RichTextEditor/readOnly";
import { isJsonString } from "../../utils/globalFunctions";

const MainCard = styled(Card)`
  display: flex;
  flex-direction: column;
  min-width: 500px;
  height:100%;
  border-radius: 14px;
  box-shadow: ${({mainCardStyle = {}}) => mainCardStyle.boxShadow ? mainCardStyle.boxShadow : "rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em"};
  color: ${({mainCardStyle = {}}) => mainCardStyle.color ? mainCardStyle.color : "black"};
  position: ${({mainCardStyle = {}}) => mainCardStyle.position ? mainCardStyle.position : "relative"};
  border: 0;
  background-color: ${({mainCardStyle = {}}) => mainCardStyle.backgroundColor ? mainCardStyle.backgroundColor : "white"}   
  ${({hoverEffect}) => hoverEffect ? `&:hover {
      box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
  }` : ""}
`

const MainCardContent = styled(CardContent)`
  overflow: auto;
  padding: ${({isJsonSummary}) => !isJsonSummary ? '1rem' : '0px'};
  padding-bottom: ${({isJsonSummary}) => !isJsonSummary ? '16px !important' : '0px !important'};
`

export const Title = styled(Typography)`
  font-size: 1.5rem;
  font-weight: 600;  
`

export const Summary = styled("p")`
  font-size: 0.9rem;
  opacity: 0.8;
  font-weight:500;
  ul {
    margin: 0
  }
`

function AboutCard({...props}) {
  const isJsonSummary = isJsonString(props?.summary);
  return (
    <MainCard
      submitButton={{visible: false}}
      cancelButton={{visible: false}}
      mainCardStyle={{ backgroundColor: '#e4e7f1' }}
    >
      <MainCardContent isJsonSummary={isJsonSummary}>
        <Summary title="">
          <ReactMarkdown rehypePlugins={[rehypeRaw]}>
            {props?.summary}
          </ReactMarkdown>
        </Summary>
      </MainCardContent>
    </MainCard>
  )
}

export default AboutCard
