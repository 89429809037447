import Autocomplete from '@mui/material/Autocomplete';

const CustomAutoComplete = ({...props}) => (
  <Autocomplete
    {...props}
    componentsProps={{
      paper: {
        sx: {
          boxShadow: 3,
        }
      }
    }}
  />
);

export default CustomAutoComplete;
