import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom';

import { styled } from "@mui/material/styles";
import Badge from '@mui/material/Badge';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Container, Alert, Box, Divider, Grid, Button, InputLabel,
  FormControl, Select, MenuItem, Slider, FormHelperText
} from "@mui/material";
import { withSwal } from 'react-sweetalert2';

import AboutCard from './about';
import Input from '../../elements/input'

import { fetchCandidateScreeningProfile, saveCandidateScreeningProfile } from '../../services/screening.service';
import { fetchSignedUrl, uploadDocumentToS3 } from '../../services/aws.service';

import GeekesInventionLogo from "../../assets/geeks-gray.png"
import ColoredSlider from "../../elements/Slider";

const MainAlert = styled(Alert)`
  margin-left: 6rem;
  border-radius: 1.5rem;
  background-color: #283665;
  font-size: 16px;
  margin-bottom: 1.5rem;
`
const DetailsDiv = styled('div')`
  margin-top: 3.5rem;
`
const LabelDiv = styled('div')`
  margin-bottom: 1.5rem;
  font-size: 16px;
  font-weight: 600;
`

const MainDivider = styled('div')`
  display: grid; 
  grid-template-columns: repeat(2,1fr);
  position: relative;
  ::after {
    content: " ";
    display: block;
    position: absolute;
    width: 2px;
    height: 100%;
    opacity: 0.8;
    margin: auto;
    border-left: 2px dashed rgb(0 0 0 / 80%);
  }
`

const HorizontalDivider = styled(Divider)`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`

const ButtonDiv = styled('div')`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
`

const DivControl = styled('div')`
  display: flex;
  flexDirection: row;
  alignItems: center;
`

const InnerDivControl = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem;
  text-transform: capitalize;
`

const LabelControl = styled(InputLabel)`
  display: flex;
  margin-top: 1.5rem;
  white-space: normal;
`

const MainFormControl = styled(FormControl)`
  margin: 1rem;
  width: 90%;
`

const SkillMainFormControl = styled(FormControl)`
  margin: 1rem;
  width: 100%
`

const ChipControl = styled('div')`
  margin: 1rem;
  width: 100%;
  padding-left: 1.5rem;
`

const BulletGrid = styled(Grid)`
  display: list-item;
`

export const CreateButton = styled(Button)(() => ({
  color: '#FFFFFF',
  backgroundColor: '#283665 !important',
  '&:hover': {
    backgroundColor: '#283665',
  },
}));

const Screening = (props) => {

  const [ candidateScreening, setCandidateScreening ] = useState({});
  const [formError, setFormError] = useState({ questions: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailLocked, setIsEmailLocked] = useState(false);

  const { screeningUuid } = useParams();
  const navigate = useNavigate();

  const fetchCandidateProfile = async () => {
    const response = await fetchCandidateScreeningProfile(screeningUuid);
    if (response?.statusCode === 200) {
      setCandidateScreening(response?.data || {});
      setIsEmailLocked(!!response?.data?.candidateEmail);
    } else if (response?.statusCode === 404) {
      navigate('/404');
    } else {
      props.swal.fire({
        title: 'Error',
        text: `Error while fetching Candidate screening, Please try again later`,
        icon: 'error',
      });
    }
  }

  useEffect(() => {
    fetchCandidateProfile();
  }, []);

  const handleInputChange = (event) => {
    if (event?.target?.value && (event?.target?.name === 'firstName' || event?.target?.name === 'lastName') && !(/^[a-zA-Z]/.test(event?.target?.value))) {
      return;
    }
    if (event?.target?.value
      && event?.target?.name === 'totalExperiance'
      && !(/^\d{0,9}$/.test(event.target.value))) {
      return;
    }
    if (event?.target?.value
      && event?.target?.name === 'expInThisProfile'
      && !(/^\d{0,9}$/.test(event.target.value))) {
      return;
    }
    formError[event?.target?.name] = "";
    setFormError(formError);
    const changedCandidateScreening = { ...candidateScreening };
    changedCandidateScreening[event?.target?.name] = event?.target?.type === 'file' ? event?.target?.files : event?.target?.value;
    setCandidateScreening(changedCandidateScreening);
  }

  const handleQuestionsChange = (event, index) => {
    if (formError?.questions?.[index]) {
      formError.questions[index] = "";
      setFormError(formError);
    }
    const changedCandidateScreening = { ...candidateScreening };
    changedCandidateScreening.questions[index].answer = event?.target?.value;
    setCandidateScreening(changedCandidateScreening);
  }

  const handleSkillSliderChange = (event, newValue, index) => {
    const changedCandidateScreening = { ...candidateScreening };
    changedCandidateScreening.skills[index].rating = newValue;
    setCandidateScreening(changedCandidateScreening);
  }

  const getSignedUrl = async (file) => {
    try {
      setIsLoading(true);
      const payload = {
        s3Input: {
          contentType: file.type,
          fileName: file.name,
          uuid: screeningUuid,
          uploadType: 'screening',
          isPublic: true
        },
      }
      const response = await fetchSignedUrl(payload)
      const { sUrl, pUrl } = response.data.signedUrl
      if (sUrl) {
        await uploadDocumentToS3(sUrl, file, file.type)
        setIsLoading(false);
        return pUrl
      }
    } catch (error) {
      setIsLoading(false);
    }
    return ""
  }

  const setCVURL = (url) => {
    if (url?.includes(process.env.REACT_APP_PUBLIC_CDN_URL)) {
      return url.replace(process.env.REACT_APP_PUBLIC_CDN_URL, '');
    }
    return url;
  }

  const handleSubmit = async () => {
    setFormError({});
    const newFormError = { questions: [] };
    let isError = false;
    const MAXIMUM_SIZE = 2 * 1024 // In MB

    const emailCheckRegex = new RegExp(/^[\w.-]+@([\w]+\.)+[\w.]{2,3}$/gi);

    if (!candidateScreening?.firstName) {
      newFormError.firstName = "Required"
      isError = true;
    }

    if (!(/^[a-zA-Z]/.test(candidateScreening?.firstName))) {
      newFormError.firstName = "Enter valid firstname"
      isError = true;
    }

    if (!candidateScreening?.lastName) {
      newFormError.lastName = "Required"
      isError = true;
    }

    if (!(/^[a-zA-Z]/.test(candidateScreening?.lastName))) {
      newFormError.lastName = "Enter valid lastname"
      isError = true;
    }

    if (candidateScreening?.candidateCVUrl?.length && (candidateScreening?.candidateCVUrl?.[0]?.size / 1024) > MAXIMUM_SIZE) {
      newFormError.candidateCVUrl = `Selected file size less then 2MB`
      isError = true;
    }

    if (!candidateScreening?.location) {
      newFormError.location = "Required"
      isError = true;
    }

    if (!candidateScreening?.totalExperiance) {
      newFormError.totalExperiance = "Required"
      isError = true;
    }

    if (!candidateScreening?.jobTitle) {
      newFormError.jobTitle = "Required"
      isError = true;
    }

    if (!candidateScreening?.candidateEmail) {
      newFormError.candidateEmail = "Required"
      isError = true;
    } else if (!emailCheckRegex.test(candidateScreening?.candidateEmail)) {
      newFormError.email = "Please enter a valid email."
      isError = true;
    }

    if (!candidateScreening?.expInThisProfile) {
      newFormError.expInThisProfile = "Required"
      isError = true;
    }

    if (candidateScreening?.totalExperiance && !(/^\d{0,9}$/.test(candidateScreening?.totalExperiance))) {
      newFormError.totalExperiance = "Enter valid total exp in IT industry"
      isError = true;
    }

    if (candidateScreening?.expInThisProfile && !(/^\d{0,9}$/.test(candidateScreening?.expInThisProfile))) {
      newFormError.expInThisProfile = "Enter valid exp in primary skills"
      isError = true;
    }

    if (!candidateScreening?.communication) {
      newFormError.communication = "Required"
      isError = true;
    }

    candidateScreening?.questions?.forEach((question, index) => {
      if (!question?.answer) {
        newFormError.questions[index] = "Required"
        isError = true;
      }
    })

    if (isError) {
      setFormError(newFormError);
      return;
    }

    let candidateCVUrl = "";
    if (candidateScreening?.candidateCVUrl?.length) {
      candidateCVUrl = await getSignedUrl(candidateScreening?.candidateCVUrl?.[0]);
    }

    const requestBody = {
      candidateCVUrl: setCVURL(candidateCVUrl),
      firstName: candidateScreening?.firstName,
      lastName: candidateScreening?.lastName,
      location: candidateScreening?.location,
      totalExperiance: Number.parseInt(candidateScreening?.totalExperiance),
      jobTitle: candidateScreening?.jobTitle,
      expInThisProfile: Number.parseInt(candidateScreening?.expInThisProfile),
      communication: candidateScreening?.communication,
      questions: candidateScreening?.questions,
      skills: candidateScreening?.skills,
      candidateEmail: candidateScreening?.candidateEmail,
    }

    setIsLoading(true);
    await saveCandidateScreeningProfile(candidateScreening?.uuid, requestBody)
      .then(response => {
        setIsLoading(false);
        if (response?.statusCode === 200) {
          props.swal.fire({
            title: 'Success',
            text: `Your response recorded successfully, HR will contact you soon.`,
            icon: 'success',
          });
          setCandidateScreening({});
          fetchCandidateProfile();
        } else {
          props.swal.fire({
            title: 'Oops',
            text: `Error while adding screening, please try again`,
            icon: 'error',
          });
        }
      }).catch(error => {
        setIsLoading(false);
        props.swal.fire({
          title: 'Oops',
          text: `Error while adding screening, please try again`,
          icon: 'error',
        });
      });
  }

  const valuetext = (value) => {
    return value;
  }

  return (
    <div>
      <Container maxWidth="xl" sx={{marginTop: "15px", overflow: "auto"}}>
        <div style={{display: "grid", gridTemplateColumns: "200px auto", gridGap: "1rem", marginTop: 15}}>
          <img style={{height: 50, maxWidth: 'inherit'}} src={GeekesInventionLogo} alt={"error"}/>
          <MainAlert icon={false} variant="filled" severity="error">
            {candidateScreening?.title ? <b>{candidateScreening?.title}&nbsp;</b> : ''}Screening request for <b>{candidateScreening?.candidateEmail}</b> from <b>Geeks Invention LLC</b>
          </MainAlert>
        </div>
        {
          candidateScreening?.summary ?
            <AboutCard summary={candidateScreening?.summary} />
          : null
        }
        {
          candidateScreening?.status === 'sent' && candidateScreening?.notes ?
            <Alert severity="info" variant="filled">Update your details {candidateScreening?.notes ? `| Note: ${candidateScreening?.notes}`: ''}</Alert>
          : null
        }
        {
          candidateScreening?.status === 'sent' ?
            <DetailsDiv>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4}>
                    <LabelDiv>
                      Basic Details
                    </LabelDiv>
                    {
                      candidateScreening?.form?.SCREENING_BASIC_QUESTIONS.map(item => {
                        return (
                          <Grid item xs={6} md={12}>
                            <DivControl>
                              <Grid item xs={6} md={4}>
                                <LabelControl>{item?.label}</LabelControl>
                              </Grid>
                              <Grid item xs={6} md={8}>
                                <MainFormControl>
                                  {
                                    item?.type === 'file' ?
                                      <Input
                                        name={item?.name}
                                        type={item?.type}
                                        variant="outlined"
                                        placeholder={item?.placeholder}
                                        InputLabelProps={{
                                          shrink: false,
                                        }}
                                        inputProps={{ accept:"application/pdf" }}
                                        size="small"
                                        onChange={handleInputChange}
                                        error={formError?.[item?.name]}
                                      />
                                    : <Input
                                      name={item?.name}
                                      type={item?.type}
                                      variant="outlined"
                                      placeholder={item?.placeholder}
                                      InputLabelProps={{
                                        shrink: false,
                                      }}
                                      size="small"
                                      value={candidateScreening?.[item?.name] || ""}
                                      onChange={handleInputChange}
                                      error={formError?.[item?.name]}
                                      disabled={item?.name === 'candidateEmail' && isEmailLocked ? true : false}
                                    />
                                  }
                                </MainFormControl>
                              </Grid>
                            </DivControl>
                          </Grid>
                        )
                      })
                    }
                  </Grid>
                  <MainDivider />
                  <Grid item xs={6} md={8}>
                    <LabelDiv>
                      Questionnaire
                    </LabelDiv>
                    {
                      candidateScreening?.form?.SCREENING_QUESTIONNAIRE.map(question => {
                        return (
                          <>
                            {
                              question?.type === 'select' ?
                                <Grid item xs={6} md={12}>
                                  <DivControl>
                                    <Grid item xs={6} md={8}>
                                      <LabelControl>
                                        {question?.label}
                                      </LabelControl>
                                    </Grid>
                                    <Grid item xs={6} md={4}>
                                      <MainFormControl sx={{ m: 1, minWidth: 200, ml: 2 }} size="small" className='test'>
                                        <Select
                                          name={question?.name}
                                          displayEmpty
                                          inputProps={{ 'aria-label': 'Without label' }}
                                          onChange={handleInputChange}
                                          value={candidateScreening?.[question?.name] || ""}
                                        >
                                          {
                                            question?.options?.map(option => (
                                              <MenuItem value={option?.value}>{option?.label}</MenuItem>
                                            ))
                                          }
                                        </Select>
                                        {
                                          formError?.[question?.name] ?
                                            <FormHelperText className="error">
                                              {formError?.[question?.name]}
                                            </FormHelperText>
                                          : null
                                        }
                                      </MainFormControl>
                                    </Grid>
                                  </DivControl>
                                </Grid>
                              : <Grid item xs={6} md={12}>
                                <DivControl>
                                  <Grid item xs={6} md={8}>
                                    <LabelControl>{question?.label}</LabelControl>
                                  </Grid>
                                  <Grid item xs={6} md={4}>
                                    <MainFormControl>
                                      <Input
                                        name={question?.name}
                                        type={question?.type}
                                        variant="outlined"
                                        placeholder={question?.placeholder}
                                        InputLabelProps={{
                                          shrink: false,
                                        }}
                                        size="small"
                                        value={candidateScreening?.[question?.name] || ""}
                                        onChange={handleInputChange}
                                        error={formError?.[question?.name]}
                                      />
                                    </MainFormControl>
                                  </Grid>
                                </DivControl>
                              </Grid>
                            }
                          </>
                        )
                      })
                    }
                    {
                      candidateScreening?.questions?.map((question, index) => {
                        return (
                          <Grid item xs={6} md={12}>
                            <DivControl>
                              <Grid item xs={6} md={8}>
                                <LabelControl>{question?.question}</LabelControl>
                              </Grid>
                              <Grid item xs={6} md={4}>
                                <MainFormControl>
                                  <Input
                                    type="text"
                                    variant="outlined"
                                    placeholder="Enter answer"
                                    InputLabelProps={{
                                      shrink: false,
                                    }}
                                    size="small"
                                    value={question?.answer || ""}
                                    onChange={(event) => handleQuestionsChange(event, index)}
                                    error={formError?.questions?.[index]}
                                  />
                                </MainFormControl>
                              </Grid>
                            </DivControl>
                          </Grid>
                        )
                      })
                    }
                    <Grid item xs={6} md={12}>
                      <DivControl>
                        <Grid item xs={6} md={12}>
                          <LabelControl>Please provide your experience in years for below skills:</LabelControl>
                        </Grid>
                      </DivControl>
                      {
                        candidateScreening?.skills?.map((skill, index) => {
                          const skillName =  skill?.name?.replace('-', ' ');
                          return (
                            <InnerDivControl>
                              <Grid item xs={6} md={2}>
                                <LabelControl style={{marginTop: 0}}>{skillName}</LabelControl>
                              </Grid>
                              <Grid item xs={6} md={3} style={{height: "23px"}}>
                                <ColoredSlider max={10} lowerTierBlockCount={6} midTierBlockCount={2} noColor={true} plus={true} fontSize={"12px"} onClick={(event, newValue) => handleSkillSliderChange(event, newValue, index)} selectedValue={skill?.rating}/>
                              </Grid>
                              <Grid item xs={6} md={3}>
                                <ChipControl>
                                  <Chip label={`${skill?.rating > 9 ? "10" : (skill?.rating || 0)} / 10`}
                                        color="primary" variant="outlined"/>
                                </ChipControl>
                              </Grid>
                            </InnerDivControl>
                          )
                        })
                      }
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <HorizontalDivider />
              <ButtonDiv>
                <CreateButton variant="contained" onClick={handleSubmit} disabled={isLoading}>
                  {
                    isLoading ?
                      <CircularProgress
                        sx={{ color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800], }}
                        size={25} color="inherit"
                      />
                    : "Submit"
                  }
                </CreateButton>
              </ButtonDiv>
            </DetailsDiv>
          : candidateScreening?.status === 'submitted' ?
            <Alert severity="warning" variant="filled">Your details submitted</Alert>
          : candidateScreening?.status === 'approved' ?
            <Alert severity="success" variant="filled">Your application approved</Alert>
          : candidateScreening?.status === 'rejected' ?
            <Alert severity="danger" variant="filled">Your application rejected {candidateScreening?.notes ? `Note: ${candidateScreening?.notes}`: ''}</Alert>
          : candidateScreening?.status ?
            <Alert severity="info" variant="filled">Your application {candidateScreening?.status} {candidateScreening?.notes ? `| Note: ${candidateScreening?.notes}`: ''}</Alert>
          : null
        }
      </Container>
    </div>
  )
}

export default withSwal(Screening)
