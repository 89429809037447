import React from 'react'
import CandidatePreScreeningComponent from "../../components/candidatePreScreening";
import SEO from '../../elements/SEO';

function CandidatePreScreening() {
  return (
    <div>
      <SEO title="Candidate Pre Screening Registration" />
      <CandidatePreScreeningComponent />
    </div>
  )
}

export default CandidatePreScreening;
