import React, { useState, useEffect, useMemo } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import CircularProgress from '@mui/material/CircularProgress';
import { Container, Grid, InputLabel, FormHelperText, Select, MenuItem } from '@mui/material';
import { isZero } from 'lodash'
import { styled } from "@mui/material/styles";
import CardElement from '../../elements/card';
import Input from '../../elements/input';
import PhoneInput from '../../elements/Phone';
import CustomAutoComplete from '../../elements/AutoComplete';

import { isValidName } from '../../utils/globalFunctions';
import { getCandidateRegistrationFields, fetchJobTemplates, registerCandidateScreeningProfile } from '../../services/screening.service';
import { fetchSignedUrl, uploadDocumentToS3 } from '../../services/aws.service';
import { withSwal } from 'react-sweetalert2';
import ColoredSlider from '../../elements/Slider';
import SelectSkills from './skills';
import { isEmpty } from 'lodash';

import Countries from '../../constants/country';
import States from '../../constants/state';

const steps = ['Basic Details', 'Experience', 'Questionnaire'];

const DivControl = styled('div')`
  display: flex;
  flexDirection: row;
  alignItems: center;
`

const LabelControl = styled(InputLabel)`
  display: flex;
  white-space: normal;
  margin-bottom: 0.5rem;
`

const InnerDivControl = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem;
  text-transform: capitalize;
`

const ChipControl = styled('div')`
  margin: 1rem;
  width: 100%;
  padding-left: 1.5rem;
`

const PreviousButton = styled(Button)(() => ({
  color: '#283665',
  borderColor: '#283665',
  marginTop: '1.8rem',
  '&:hover': {
    borderColor: '#283665',
  },
}));

const CreateButton = styled(Button)(() => ({
  color: '#FFFFFF',
  backgroundColor: '#ff7903 !important',
  '&:hover': {
    backgroundColor: '#ff7903',
  },
}));


const HorizontalLinearStepper = (props) => {
  const initialData = {
    firstName: "",
    lastName: "",
    candidateEmail: "",
    phone: "",
    phoneCode: "",
    phoneString: "",
    phoneFormat: "",
    location: "",
    jobTitle: "",
    totalExperiance: "",
    expInThisProfile: "",
    sortName: "",
    jobTemplate: {},
    skills: [],
    questions: [],
    communication: "",
    candidateCVUrl: "",
    password: "",
    country: {},
    state: ""
  };

  const [activeStep, setActiveStep] = useState(0);
  const [skipped, setSkipped] = useState(new Set());
  const [candidateData, setCandidateData] = useState({...initialData});
  const [formError, setFormError] = useState({});
  const [registrationFields, setRegistrationFields] = useState({});
  const [jobTemplates, setJobTemplates] = useState({});
  const [selectedJobTemplate, setSelectedJobTemplate] = useState({});

  const [selectedSkills, setSelectedSkills] = useState([]);
  const [skillNames, setSkillNames] = useState({});
  const [skillInitialData, setSkillInitialData] = useState([]);
  const [errorState, setErrorState] = useState({});
  const [screeningLink, setScreeningLink] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const getRegistrationFields = async () => {
    const response = await getCandidateRegistrationFields();
    if (response?.statusCode === 200) {
      setRegistrationFields(response?.data || {});
    } else {
      props.swal.fire({
        title: 'Error',
        text: `Error while fetching Candidate registration fields, Please try again later`,
        icon: 'error',
      });
    }
  }

  const getJobTemplates = async () => {
    const response = await fetchJobTemplates();
    if (response?.statusCode === 200) {
      setJobTemplates(response?.data?.templates || {});
    } else {
      props.swal.fire({
        title: 'Error',
        text: `Error while fetching Candidate registration fields, Please try again later`,
        icon: 'error',
      });
    }
  }

  useEffect(() => {
    getRegistrationFields();
    getJobTemplates();
  }, []);

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNextStepChange = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  }

  const handleNext = async () => {
    const validSkills = await handleSelectedSkills();
    const isValid = handleValidateData();
    if (isValid && validSkills) {
      if (activeStep === 2) {
        handleSubmit();
      } else {
        handleNextStepChange();
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleInputChange = (event) => {
    if (event?.target?.value && (event?.target?.name === 'name' || event?.target?.name === 'title') && !isValidName(event?.target?.value)) {
      return;
    }
    if (event?.target?.value
      && event?.target?.name === 'totalExperiance'
      && !(/^\d{0,9}$/.test(event.target.value))) {
      return;
    }
    if (event?.target?.value
      && event?.target?.name === 'expInThisProfile'
      && !(/^\d{0,9}$/.test(event.target.value))) {
      return;
    }
    formError[event?.target?.name] = "";
    setFormError(formError);
    const changedCandidateData = { ...candidateData };
    changedCandidateData[event?.target?.name] = event?.target?.type === 'file' ? event?.target?.files : event?.target?.value;
    setCandidateData(changedCandidateData);
  }

  const handlePhoneChange = (event, data, e) => {
    formError['phone'] = "";
    setFormError(formError);
    const changedCandidateData = {...candidateData};
    changedCandidateData.phone = event;
    changedCandidateData.phoneCode = data.dialCode;
    changedCandidateData.phoneString = e.target.value;
    changedCandidateData.phoneFormat = data?.format;
    setCandidateData(changedCandidateData);
  }

  const handleSkillSliderChange = (event, newValue, index) => {
    const changedCandidateData = { ...candidateData };
    changedCandidateData.skills[index].rating = newValue;
    setCandidateData(changedCandidateData);
  }

  const handleQuestionsChange = (event, index) => {
    if (formError?.questions?.[index]) {
      formError.questions[index] = "";
      setFormError(formError);
    }
    const changedCandidateData = { ...candidateData };
    changedCandidateData.questions[index].answer = event?.target?.value;
    setCandidateData(changedCandidateData);
  }

  const jobTemplateChange = (event, values) => {
    setFormError({ ...formError, jobTemplate: "" });
    setSelectedJobTemplate(values);
    const jobTemplateSkills = values?.skills?.map(skill => ({ name: skill, experience: 1, isPrimary: false })) || [];
    setSelectedSkills([...jobTemplateSkills]);
  }

  useMemo(() => {
    const changedCandidateData = { ...candidateData };
    changedCandidateData.skills = [...selectedSkills].map(skill => ({ name: skill.name, rating: 0 }));
    changedCandidateData.questions = {...selectedJobTemplate}?.questions?.map(que => ({ question: que, answer: "" }));
    setCandidateData(changedCandidateData);
    return changedCandidateData.skills;
  }, [selectedSkills, selectedJobTemplate]);

  // useMemo(() => {
  //   const changedCandidateData = { ...candidateData };
  //   changedCandidateData.questions = {...selectedJobTemplate}?.questions?.map(que => ({ question: que, answer: "" }));
  //   setCandidateData(changedCandidateData);
  //   return changedCandidateData.questions;
  // }, [selectedJobTemplate]);

  const handleSelectedSkills = async () => {
    const localErrorState = {};
    selectedSkills.forEach(({ name, experience }) => {
      if (!experience) {
        localErrorState[name] = true;
      }
    });
    if (Object.keys(localErrorState).length > 0) {
      setErrorState(localErrorState);
      return false;
    }
    setErrorState(localErrorState);
    return true
  }

  const handleSelectChange = (event, values, name) => {
    formError[name] = "";
    setFormError(formError);
    const changedCandidateData = { ...candidateData };
    if (name === 'country') {
      changedCandidateData.country = {
        ...changedCandidateData.country,
        name: values?.name,
        sortName: values?.sortname
      };
      changedCandidateData.state = "";
    } else if (name === 'state') {
      changedCandidateData.state = values?.name;
    }
    setCandidateData(changedCandidateData);
  }

  const setCVURL = (url) => {
    if (url?.includes(process.env.REACT_APP_PUBLIC_CDN_URL)) {
      return url.replace(process.env.REACT_APP_PUBLIC_CDN_URL, '');
    }
    return url;
  }

  const getSignedUrl = async (file) => {
    try {
      setIsLoading(true);
      const payload = {
        s3Input: {
          contentType: file.type,
          fileName: file.name,
          uuid: "candidate_screening_public",
          uploadType: 'screening',
          isPublic: true
        },
      }
      const response = await fetchSignedUrl(payload)
      const { sUrl, pUrl } = response.data.signedUrl
      if (sUrl) {
        await uploadDocumentToS3(sUrl, file, file.type)
        setIsLoading(false);
        return pUrl
      }
    } catch (error) {
      setIsLoading(false);
    }
    return ""
  }

  const handleValidateData = () => {
    setFormError({});

    let isError = false;
    const MAXIMUM_SIZE = 2 * 1024 // In MB
    const newFormError = { questions: [] };

    if (activeStep === 0) {

      const emailCheckRegex = new RegExp(/^[\w.-]+@([\w]+\.)+[\w.]{2,3}$/gi);

      if (!candidateData?.firstName) {
        newFormError.firstName = "Required"
        isError = true;
      }

      if (!(/^[a-zA-Z]/.test(candidateData?.firstName))) {
        newFormError.firstName = "Enter valid first name"
        isError = true;
      }

      if (!candidateData?.lastName) {
        newFormError.lastName = "Required"
        isError = true;
      }

      if (!(/^[a-zA-Z]/.test(candidateData?.lastName))) {
        newFormError.lastName = "Enter valid last name"
        isError = true;
      }


      if (!candidateData?.country?.name) {
        newFormError.country = "Select country"
        isError = true;
      }

      if (!candidateData?.state) {
        newFormError.state = "Select state"
        isError = true;
      }

      if (!candidateData?.candidateEmail) {
        newFormError.candidateEmail = "Required"
        isError = true;
      } else if (!emailCheckRegex.test(candidateData?.candidateEmail)) {
        newFormError.candidateEmail = "Please enter a valid email."
        isError = true;
      }

      if (!candidateData?.phone) {
        newFormError.phone = "Enter phone number"
        isError = true;
      }
      
      if (candidateData?.phone
        && candidateData?.phone !== candidateData?.phoneCode
        && candidateData?.phoneFormat
        && candidateData?.phoneFormat !== String(candidateData?.phoneString).replace(/[0-9]/g, ".")) {
        newFormError.phone = "Enter valid phone number"
        isError = true;
      }

      if (!candidateData?.password) {
        newFormError.password = "Required"
        isError = true;
      }

      if (candidateData?.password && !candidateData?.password.match(/\d/) || !candidateData?.password.match(/[a-zA-Z]/) || candidateData?.password?.length < 8) {
        newFormError.password = "Password must contain at least one letter and one number and minimum 8 characters"
        isError = true;
      }
    } else if (activeStep === 1) {
      if (!candidateData?.jobTitle) {
        newFormError.jobTitle = "Required"
        isError = true;
      }
     
      if (!candidateData?.totalExperiance) {
        newFormError.totalExperiance = "Required"
        isError = true;
      }

      if (!candidateData?.expInThisProfile) {
        newFormError.expInThisProfile = "Required"
        isError = true;
      }

      if ((candidateData?.totalExperiance || candidateData?.totalExperiance === 0)
        && (!(/^\d{0,9}$/.test(candidateData?.totalExperiance)) || candidateData?.totalExperiance <= 0)) {
        newFormError.totalExperiance = "Enter valid total exp in IT industry"
        isError = true;
      }
  
      if ((candidateData?.expInThisProfile || candidateData?.expInThisProfile === 0)
        && (!(/^\d{0,9}$/.test(candidateData?.expInThisProfile)) || candidateData?.expInThisProfile <= 0)) {
        newFormError.expInThisProfile = "Enter valid exp in primary skills"
        isError = true;
      }

      if (!selectedJobTemplate || isEmpty(selectedJobTemplate)) {
        newFormError.jobTemplate = "Required"
        isError = true;
      }
    } else if (activeStep === 2) {
      if (!candidateData?.communication) {
        newFormError.communication = "Required"
        isError = true;
      }

      candidateData?.questions?.forEach((question, index) => {
        if (!question?.answer) {
          newFormError.questions[index] = "Required"
          isError = true;
        }
      })

      if (candidateData?.candidateCVUrl?.length && (candidateData?.candidateCVUrl?.[0]?.size / 1024) > MAXIMUM_SIZE) {
        newFormError.candidateCVUrl = `Selected file size less then 2MB`
        isError = true;
      }
    }

    if (isError) {
      setFormError(newFormError);
      return false;
    }
    return true;
  }

  const handleSubmit = async () => {

    let candidateCVUrl = "";
    if (candidateData?.candidateCVUrl?.length) {
      candidateCVUrl = await getSignedUrl(candidateData?.candidateCVUrl?.[0]);
    }

    const requestBody = {
      candidateCVUrl: setCVURL(candidateCVUrl),
      jobTemplateId: selectedJobTemplate?._id,
      title: selectedJobTemplate?.title,
      summary: selectedJobTemplate?.summary,
      name: `${candidateData?.firstName || ""} ${candidateData?.lastName || ""}`,
      firstName: candidateData?.firstName,
      lastName: candidateData?.lastName,
      location: `${candidateData?.state}, ${candidateData?.country?.name}`,
      totalExperiance: Number.parseInt(candidateData?.totalExperiance),
      jobTitle: candidateData?.jobTitle,
      expInThisProfile: Number.parseInt(candidateData?.expInThisProfile),
      communication: candidateData?.communication,
      questions: candidateData?.questions,
      skills: candidateData?.skills,
      candidateEmail: candidateData?.candidateEmail,
      password: candidateData?.password
    }

    setIsLoading(true);
    await registerCandidateScreeningProfile(requestBody)
      .then(response => {
        setIsLoading(false);
        if (response?.statusCode >= 200 && response?.statusCode < 400) {
          handleNextStepChange();
          setScreeningLink(`${window.location.origin}/screening/${response?.data?.uuid}`);
          props.swal.fire({
            title: 'Success',
            text: `Your response recorded successfully, HR will contact you soon.`,
            icon: 'success',
          });
        } else {
          props.swal.fire({
            title: 'Oops',
            text: `${response?.message || 'Error while adding screening, please try again'}`,
            icon: 'error',
          });
        }
      }).catch(error => {
        setIsLoading(false);
        props.swal.fire({
          title: 'Oops',
          text: `${error?.message || 'Error while adding screening, please try again'}`,
          icon: 'error',
        });
      });
  }

  const stepOneFields = ['firstName', 'lastName', 'candidateEmail', 'phone'];
  const stepSecondFields = ['jobTitle', 'totalExperiance', 'expInThisProfile'];
  const stepFileFields = ['candidateCVUrl'];

  const stepOneFormFields = registrationFields?.form?.SCREENING_BASIC_QUESTIONS?.filter(field => stepOneFields.includes(field.name));

  const stepSecondFormFields = stepSecondFields?.map(field => {
    return registrationFields?.form?.SCREENING_BASIC_QUESTIONS?.find(fieldObject => fieldObject?.name === field);
  });

  const stepFormFileField = stepFileFields?.map(field => {
    return registrationFields?.form?.SCREENING_BASIC_QUESTIONS?.find(fieldObject => fieldObject?.name === field);
  });

  const selectedCountry = Countries?.find(item => item?.sortname === candidateData?.country?.sortName);

  const selectedStateOptions = States?.[selectedCountry?.id?.toString()] || [];

  const selectedState = selectedStateOptions?.find(item => item.name === candidateData?.state);

  return (
    <Container maxWidth="md" sx={{marginTop: "2rem", overflow: "auto"}}>

      <Box sx={{ width: '100%' }}>
        <Box mb={4}>
          <h1 className="heading text-center">Candidate Registration</h1>
        </Box>
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map((label, index) => {
            const stepProps = {};
            const labelProps = {};
            if (isStepSkipped(index)) {
              stepProps.completed = false;
            }
            return (
              <Step key={label} {...stepProps}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <Box mb={4} mt={2}>
          <CardElement>

            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Your response recorded successfully, HR will contact you soon.
                </Typography>
                <Typography sx={{ mt: 2, mb: 1 }}>
                  Here is your screening link for future referance <a href={`${screeningLink}`} target='_blank'>Click Here.</a>
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {
                  activeStep === 0 ?
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        {
                          stepOneFormFields?.map((item) => {
                            return (
                              <Grid item xs={12} md={6}>
                                <Input
                                  title={item?.label}
                                  name={item?.name}
                                  type={item?.type}
                                  variant="outlined"
                                  placeholder={item?.placeholder}
                                  InputLabelProps={{
                                    shrink: false,
                                  }}
                                  size="small"
                                  value={candidateData?.[item?.name] || ""}
                                  onChange={handleInputChange}
                                  error={formError?.[item?.name]}
                                />
                              </Grid>
                            )
                          })
                        }
                        <Grid item xs={12} md={6}>
                          <PhoneInput
                            title="Phone"
                            country={candidateData?.sortName?.toLowerCase() || 'in'}
                            value={candidateData?.phone || ""}
                            onChange={(phoneNumber, data, event) => handlePhoneChange(phoneNumber, data, event)}
                            countryCodeEditable={false}
                          />
                          {
                            formError?.phone ?
                              <FormHelperText className="error">
                                {formError?.phone}
                              </FormHelperText>
                            : null
                          }
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomAutoComplete
                            id="country"
                            name="country"
                            options={Countries}
                            getOptionLabel={(option) => option.name || ""}
                            onChange={(event, values) => handleSelectChange(event, values, 'country')}
                            value={selectedCountry || {}}
                            renderInput={(params) => (
                              <Input
                                {...params}
                                type="text"
                                variant="outlined"
                                title="Country"
                                placeholder="Select Country"
                                InputLabelProps={{
                                  shrink: false,
                                }}
                                size="small"
                              />
                            )}
                          />
                          {
                            formError?.country ?
                              <FormHelperText className="error">
                                {formError?.country}
                              </FormHelperText>
                            : null
                          }
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <CustomAutoComplete
                            id="state"
                            name="state"
                            options={selectedStateOptions || []}
                            getOptionLabel={(option) => option.name || ""}
                            onChange={(event, values) => handleSelectChange(event, values, 'state')}
                            value={selectedState || {}}
                            renderInput={(params) => (
                              <Input
                                {...params}
                                type="text"
                                name="state"
                                variant="outlined"
                                title="State"
                                placeholder="Select state"
                                InputLabelProps={{
                                  shrink: false,
                                }}
                                size="small"
                              />
                            )}
                          />
                          {
                            formError?.state ?
                              <FormHelperText className="error">
                                {formError?.state}
                              </FormHelperText>
                            : null
                          }
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Input
                            title="Password"
                            name="password"
                            type="password"
                            variant="outlined"
                            placeholder="Enter Password"
                            InputLabelProps={{
                              shrink: false,
                            }}
                            size="small"
                            value={candidateData?.password || ""}
                            onChange={handleInputChange}
                            error={formError?.password}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  : activeStep === 1 ?
                    <Box sx={{ flexGrow: 1 }}>
                      <Grid container spacing={2}>
                        {
                          stepSecondFormFields?.map((item) => {
                            return (
                              <Grid item xs={12} md={6}>
                                <Input
                                  title={item?.label}
                                  name={item?.name}
                                  type={item?.type}
                                  variant="outlined"
                                  placeholder={item?.placeholder}
                                  InputLabelProps={{
                                    shrink: false,
                                  }}
                                  size="small"
                                  value={candidateData?.[item?.name] || ""}
                                  onChange={handleInputChange}
                                  error={formError?.[item?.name]}
                                />
                              </Grid>
                            )
                          })
                        }
                        <Grid item xs={12} md={6}>
                          <CustomAutoComplete
                            id="job-templates"
                            options={jobTemplates || []}
                            getOptionLabel={(option) => `${option?.title ? option?.title :""}`}
                            onChange={jobTemplateChange}
                            value={selectedJobTemplate || ""}
                            renderInput={(params) => (
                              <Input
                                {...params}
                                type="text"
                                variant="outlined"
                                title="Role"
                                placeholder="Select role"
                                InputLabelProps={{
                                  shrink: false,
                                }}
                                size="small"
                                error={formError?.jobTemplate}
                              />
                            )}
                          />
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <LabelControl>Skills</LabelControl>
                          <SelectSkills
                            key={selectedSkills}
                            handleSubmit={handleSelectedSkills}
                            candidateSkills={[...selectedSkills]}
                            selectedSkills={selectedSkills}
                            setSelectedSkills={setSelectedSkills}
                            skillInitialData={skillInitialData}
                            setSkillInitialData={setSkillInitialData}
                            errorState={errorState}
                            setErrorState={setErrorState}
                            setSkillNames={setSkillNames}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  : activeStep === 2 ?
                    <Box sx={{ flexGrow: 1 }}>
                      {
                          registrationFields?.form?.SCREENING_QUESTIONNAIRE.map(question => {
                            return (
                              <>
                                {
                                  question?.type === 'select' ?
                                    <Grid item xs={6} md={12} mb={2}>
                                      <LabelControl>
                                        {question?.label}
                                      </LabelControl>
                                      <Select
                                        style={{ width: '100%', height: '40px' }}
                                        name={question?.name}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        onChange={handleInputChange}
                                        value={candidateData?.[question?.name] || ""}
                                      >
                                        {
                                          question?.options?.map(option => (
                                            <MenuItem value={option?.value}>{option?.label}</MenuItem>
                                          ))
                                        }
                                      </Select>
                                      {
                                        formError?.[question?.name] ?
                                          <FormHelperText className="error">
                                            {formError?.[question?.name]}
                                          </FormHelperText>
                                        : null
                                      }
                                    </Grid>
                                  : <Grid item xs={6} md={12}>
                                      <LabelControl>{question?.label}</LabelControl>
                                      <Input
                                        name={question?.name}
                                        type={question?.type}
                                        variant="outlined"
                                        placeholder={question?.placeholder}
                                        InputLabelProps={{
                                          shrink: false,
                                        }}
                                        size="small"
                                        value={candidateData?.[question?.name] || ""}
                                        onChange={handleInputChange}
                                        error={formError?.[question?.name]}
                                      />
                                  </Grid>
                                }
                              </>
                            )
                          })
                        }
                      <Grid container spacing={2}>
                        {
                          candidateData?.questions?.map((question, index) => {
                            return (
                              <Grid item xs={12} md={12} mb={2}>
                                <LabelControl>{question?.question}</LabelControl>
                                <Input
                                  type="text"
                                  variant="outlined"
                                  placeholder="Enter answer"
                                  InputLabelProps={{
                                    shrink: false,
                                  }}
                                  size="small"
                                  value={question?.answer || ""}
                                  onChange={(event) => handleQuestionsChange(event, index)}
                                  error={formError?.questions?.[index]}
                                />
                              </Grid>
                            )
                          })
                        }
                      </Grid>
                      {
                          stepFormFileField?.map((item) => {
                            return (
                              <Grid item xs={12} md={6} mb={2}>
                                <Input
                                  title={item?.label}
                                  name={item?.name}
                                  type={item?.type}
                                  variant="outlined"
                                  placeholder={item?.placeholder}
                                  InputLabelProps={{
                                    shrink: false,
                                  }}
                                  inputProps={{ accept:"application/pdf" }}
                                  size="small"
                                  onChange={handleInputChange}
                                  error={formError?.[item?.name]}
                                />
                              </Grid>
                            )
                          })
                        }
                      <Grid item xs={12} md={12}>
                        <LabelControl>Please rate yourself on a scale of 0 to 10 where 10 experts and 0 means no knowledge.</LabelControl>
                        {
                          candidateData?.skills?.map((skill, index) => {
                            return (
                              <InnerDivControl>
                                <Grid item xs={6} md={1} mr={2} style={{ width: '15%' }}>
                                  <LabelControl style={{marginTop: 0}}>{skillNames[skill?.name]}</LabelControl>
                                </Grid>
                                <Grid item xs={6} md={3}>
                                  <ColoredSlider
                                    height={"40px"}
                                    max={10}
                                    lowerTierBlockCount={6}
                                    midTierBlockCount={2}
                                    plus={true}
                                    fontSize={"12px"}
                                    onClick={(event, newValue) => handleSkillSliderChange(event, newValue, index)} selectedValue={skill?.rating}
                                  />
                                </Grid>
                                <Grid item xs={6} md={3}>
                                  <ChipControl>
                                    <Chip
                                      label={`${skill?.rating > 9 ? "10" : (skill?.rating || 0)} / 10`}
                                      color="primary"
                                      variant="outlined"
                                    />
                                  </ChipControl>
                                </Grid>
                              </InnerDivControl>
                            )
                          })
                        }
                      </Grid>
                    </Box>
                  : null
                }
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Box sx={{ flex: '1 1 auto' }} />

                  <Box mr={2}>
                    <PreviousButton variant="outlined"
                      
                      disabled={activeStep === 0}
                      onClick={handleBack}
                    >
                      Back
                    </PreviousButton>
                  </Box>

                  <Box sx={{ marginTop: '28px' }}>
                    <CreateButton onClick={handleNext} variant="contained" disabled={isLoading}>
                      {
                        isLoading ?
                          <CircularProgress
                            sx={{ color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800], }}
                            size={25} color="inherit"
                          />
                        : activeStep === steps.length - 1 ? 'Register' : 'Next'
                      }
                    </CreateButton>
                  </Box>
                </Box>
              </React.Fragment>
            )}
          </CardElement>
        </Box>
      </Box>
    </Container>
  );
}

export default withSwal(HorizontalLinearStepper)