import React, { memo } from 'react'
import { eachMinuteOfInterval, endOfDay, endOfToday, isSameMinute, set, startOfDay, startOfToday } from 'date-fns'
import { cn } from '../../utils/globalFunctions'
import { START_HOUR, END_HOUR, STEPS } from './timeConfig'

// eslint-disable-next-line react/display-name
const TimesBar = memo(({times}) => {

  const StartOfWeek = startOfDay(times[0])
  const EndtOfWeek = endOfDay(times[0])
  const startHour = set(StartOfWeek, { hours: START_HOUR })
  const endHour = set(EndtOfWeek, { hours: END_HOUR })
  let hoursInDay = eachMinuteOfInterval(
    {
      start: startHour,
      end: endHour,
    },
    { step: STEPS }
  )

  return (
    <div className={cn(`w-[42px]`, 'flex justify-center rounded-md overflow-hidden')}>
      <div className='flex rounded-md'>
        {hoursInDay.map((hour, i) => {
          return (
            <div
              key={i}
              className={cn(
                times.some((time) => isSameMinute(hour, time)) &&
                  "h-[4px] w-[1px] bg-green-300",
                !times.some((time) => isSameMinute(hour, time)) &&
                  "h-[4px] w-[1px] bg-gray-100"
              )}
            />
          )
        })}
      </div>
    </div>
  )
})

export default TimesBar