import * as React from 'react';
import {useEffect} from 'react';
import TextField from '../input';
import Autocomplete from '@mui/material/Autocomplete';
import {StyledChips} from "../Chip/NormalChip";

export default React.forwardRef(function AutocompleteMultiselect({
                                                                     inputProps,
                                                                     data = [],
                                                                     displayLabel,
                                                                     selectedData,
                                                                     equalKey,
                                                                     renderEmpty,
                                                                     disableClearable,
                                                                     multiple,
                                                                     minWidth,
                                                                     ...props
                                                                 }, ref) {
    const [value, setValue] = React.useState(selectedData ?? multiple ? [] : null);

    useEffect(() => {
        if (multiple && selectedData?.length <= 0 && value?.length > 0) {
            setValue(multiple ? [] : null);
        }
        if ((multiple && selectedData?.length >= 0) || !!selectedData) {
            setValue(selectedData);
        }
    }, [multiple ? selectedData?.length : !!selectedData])

    const onChange = (newValue) => {

        setValue(multiple ? [
            ...newValue
        ] : newValue);
        if (ref) {
            ref.current["value"] = multiple ? [...value] : value;
        }
        if (props.onChange) {
            props.onChange(multiple ? [...newValue] : newValue)
        }
    }

    return (
        <Autocomplete
            {...props}
            multiple={multiple}
            componentsProps={{
                paper: {
                    sx: {
                        boxShadow: 3,
                    }
                }
            }}
            // id="fixed-tags-demo"
            ref={(me) => {
                if (ref) {
                    if (!ref.current) ref.current = {}
                    ref.current["element"] = me
                    ref.current["value"] = [...value]
                }
            }}
            value={value}
            onChange={(event, newValue) => {
                onChange(newValue)
            }}
            options={data}
            isOptionEqualToValue={(option, value) => {
                return equalKey ? option[equalKey] === value?.[equalKey] : option === value;
            }}
            getOptionLabel={(option) => displayLabel ? option[displayLabel] : option}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <StyledChips
                        label={option?.[displayLabel] || ""}
                        {...getTagProps({index})}
                    />
                ))
            }
            style={{minWidth: minWidth || 500}}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        {...inputProps}
                        InputProps={{...params.InputProps, ...(inputProps?.InputProps ?? {})}}
                        inputProps={{
                            ...params.inputProps,
                            onChange: (e) => {
                                params.inputProps.onChange(e)
                            },
                            onKeyDown: disableClearable ? (e) => {
                                if (e.code === "Backspace" && !e.target.value?.trim()) {
                                    e.stopPropagation();
                                }
                            } : undefined
                        }}
                    />
                )
            }}
        />
    );
})
