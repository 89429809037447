import UserProfileImg from "../../../assets/default_user_profile.jpg";
import Trophy from "../../../assets/trophy.svg"
import React from "react";

const LeftSideProfile = ({candidateData = {}}) => {
    return (
        <div className={"left-profile"}>
            <div className="intro-box section-padding">
                <div className="talent-img">
                    <figure className="tal-fig">
                        <img
                            alt="Rahul Dhangar"
                            data-src="https://www.uplers.com/wp-content/themes/uplers/assets/images/profiles/rahul-dhangar.png"
                            className=" lazyloaded"
                            src={candidateData.photo || UserProfileImg}
                        />
                    </figure>
                </div>
                <div className="talent-details">
                    <h1 className="talent-name">{candidateData.name}</h1>
                    <p className="designation">{candidateData.title}</p>
                </div>
                <div className="talent-exp">
                    <p className="mb-0">Experience: {candidateData?.totalExperience} Years</p>
                </div>
                {/*<div className="talent-batch">
                                    <figure><img alt="Full Stack Stamp"
                                                 data-src="https://www.uplers.com/wp-content/themes/uplers/assets/images/profiles/full stack stamp.png"
                                                 className=" lazyloaded"
                                                 src="./Profiles - Rahul Dhangar - Uplers_files/full stack stamp.png"/>
                                    </figure>
                                </div>*/}
            </div>
            <div className="interpersonal-skills section-padding">
                <h2 className="profile-titles">INTERPERSONAL SKILLS</h2>
                <div className="interpersonal-list">
                    {[...(candidateData?.extraSkills ?? ["Leadership", "Time Management", "Proactive", "Team Player", "Quick Learner", "Problem Solver"])]?.map((value, index) => {
                        return (
                            <div className="interpersonal-item">
                                <p className={index === 5 ? "mb-0" : ""}>{value}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="talent-achievements section-padding">
                <h2 className="profile-titles">ACHIEVEMENTS</h2>
                <div className="achievements-list">
                    {candidateData?.achievement?.map(({title, summary}) => {
                        return (
                            <div className="achievements-item">
                                <figure>
                                    <img
                                        alt="Project's Success"
                                        className=" ls-is-cached lazyloaded"
                                        src={Trophy}
                                    />
                                </figure>
                                <p className="achievement-title">{true ? "Project's Success" : title}</p>
                                <p className="achievement-desc">{true ? `Collective efforts from his team helped
                                                    ShriramLife Insurance Company
                                                    go digital and increase its monthly revenue by $ 1.3 million.` : summary}</p>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="talent-interests section-padding border-0">
                <h2 className="profile-titles">INTERESTS</h2>
                <div className="interests-list">
                    {[...(candidateData?.interest ?? ["Ches"])]?.map((value) => {
                        return (
                            <div className="interests-item">
                                {/* <figure>
                                <img alt="Chess"
                                     data-src="https://www.uplers.com/wp-content/themes/uplers/assets/images/profiles/chess.svg"
                                     className=" lazyloaded"
                                     src="./Profiles - Rahul Dhangar - Uplers_files/chess.svg"/>
                            </figure>*/}
                                <p>{value}</p>
                            </div>)
                    })}
                </div>
            </div>
        </div>
    )
}

export default LeftSideProfile;
