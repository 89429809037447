import LeftSideProfile from "./profile/LeftSideProfiler";
import RightSideProfile from "./profile/RightSideProfile";
import {useParams} from "react-router-dom";
import {Grid} from "@mui/material";
import {withSwal} from 'react-sweetalert2';
import MainCard from "../../elements/card";
import {useEffect, useMemo, useState} from "react";
import {fetchCandidateDetails, fetchSkills} from "../../services/candidate.service";
import "./styles.scss"

const CandidateProfile = (props) => {
    const {candidateId} = useParams();
    const [candidateData, setCandidateData] = useState({});
    const [skills, setSkills] = useState([])

    const getCandidateDetails = async () => {
        try {
            const skillsResponse = await fetchSkills()
            setSkills(skillsResponse)
            const response = await fetchCandidateDetails(candidateId);
            if (response?.statusCode === 200) {
                setCandidateData(response?.data || {});
            } else {
                // navigate("/404")
                props.swal.fire({
                    title: 'Error',
                    text: `Error while fetching Candidate details, Please try again later`,
                    icon: 'error',
                });
            }
        } catch (error) {
            console.error(error)
            // navigate("/404")
            props.swal.fire({
                title: 'Error',
                text: `Error while fetching Candidate details, Please try again later`,
                icon: 'error',
            });
        }
    }

    useEffect(() => {
        getCandidateDetails();
    }, []);


    const modifiedSkill = useMemo(() => {
        const transformedSkill = {};
        skills?.forEach(({slug, ...props}) => {
            transformedSkill[slug] = props;
        })
        return transformedSkill;
    }, [skills])

    return (
        <MainCard contentSX={{padding: "0 !important"}}>
            <div id={"candidate-profile-view"}>
                <Grid container>
                    <Grid item md={4} xs={12} padding={0}>
                        <LeftSideProfile candidateData={candidateData}/>
                    </Grid>
                    <Grid item md={8} xs={12} padding={0}>
                        <RightSideProfile candidateData={candidateData} modifiedSkill={modifiedSkill}/>
                    </Grid>
                </Grid>
            </div>
        </MainCard>
    )
}

export default withSwal(CandidateProfile)
