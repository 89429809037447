import React from 'react'
import CalendarComponent from "../../elements/calendar";
import SEO from '../../elements/SEO';

function CalendarAvailability() {
  return (
    <div>
      <SEO title="Candidate Availability" />
      <CalendarComponent />
    </div>
  )
}

export default CalendarAvailability
