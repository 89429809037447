import React from "react";
import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import PageNotFoundImg from "../../assets/404_2.jpg";
import { CustomButton } from "../../elements/Button/CommonButton";
import SEO from "../../elements/SEO";

const CenterDiv = styled(`div`)`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100vh;
`

const ErrorPageWrapper = styled("div")`
  width: 100%;
  height:100%;
  display: flex;
  justify-content: space-around;
  overflow: hidden;

  > div:nth-child(1) {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 60px auto 0;

    div > p:nth-child(1) {
      font-size: 4rem;
      font-weight: 900;
      margin: 0;
      text-shadow: 2px 0 #000000;
      line-height: normal;
    }

    div > p:nth-child(2) {
      font-size: 1.2rem;
    }
  }

   > div:nth-child(2) {
      width: 50%;
      margin: 0 auto;
      img {
        width:100%;
      }
   }

   @media (max-width: 1024px) {
    > div:nth-child(1) {
      margin-top: 30px;
      div > p:nth-child(1) {
        font-size: 2.5rem;
      }

      div > p:nth-child(2) {
        font-size: 0.7rem;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column-reverse;

      > div:nth-child(1) {
        margin-top: 30px;

        div > p:nth-child(1) {
           font-size: 4rem;
        }

        div > p:nth-child(2) {
          font-size: 1.1rem;
        }
      }

      > div:nth-child(2) {
        overflow:hidden;
        width: 70%;
        img {
         // transform: scale(1.2);
         transform-origin: top left;
        }
      }
   }

   @media (max-width: 425px) {
       > div:nth-child(1) {
         width: 100%;
       }

       > div:nth-child(2) {
          width: 100%;
       }
   }
`;

const PageNotFound = () => {
    const navigate = useNavigate()
    return (
      <CenterDiv>
        <SEO title="404 Page not found" />
        <ErrorPageWrapper>
          <div>
            <div>
              <p> Oh no! </p>
              <p> The page you are trying to access is not available. </p>
              <CustomButton type={"primary"} onClick={()=> { navigate("/") }}>Home Page</CustomButton>
            </div>
          </div>
          <div>
            <img src={PageNotFoundImg} alt={"error"}/>
          </div>
        </ErrorPageWrapper>
      </CenterDiv>
    );
};

export default PageNotFound;
