import React from 'react'
import ScreeningComponent from "../../components/screening";
import SEO from '../../elements/SEO';

function Screening() {
  return (
    <div>
      <SEO title="Candidate Screening" />
      <ScreeningComponent/>
    </div>
  )
}

export default Screening
