import React from 'react'
import ProposalContainer from "../../containers/proposal";
import SEO from '../../elements/SEO';

function Proposal() {
    return (
        <div>
            <SEO title="Proposal" />
            <ProposalContainer/>
        </div>
    )
}

export default Proposal
