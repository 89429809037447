import * as actionTypes from '../../actions/skills/actionTypes';

export const initialState = {
  skills: [],
};

const skillReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SKILLS:
      return {
        ...state,
        skills: action.data
      };
    default:
      return state;
  }
};

export default skillReducer;
