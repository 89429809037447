import {styled} from "@mui/material/styles";

export const Title =  styled("h1")`

`

export const SubTitle =  styled("h2")`
      color: #666666;
`

export const SubTitleH3 =  styled("h3")`
      color: #666666;
`

export const SubTitleH4 =  styled("h4")`
      color: #666666;
`
